/* Style inputs */
.contactus_form{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    max-width: 1080px; /* Adjust max-width as needed */
    height: 80vh;
    margin: 0 auto; /* Center content horizontally */
    padding: 20px; /* Add padding to content */
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: white;
}
.contactus_form input[type=text],input[type=email],input[type=tel],textarea, select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  /* Style the submit button */
.contactus_form input[type=submit] {
    width: 100%;
    background-color: #04AA6D;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
.contactUsSec{
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    z-index: 1;
    max-width: 1080px; /* Adjust max-width as needed */
    height: 80vh;
    margin: 0 auto; /* Center content horizontally */
    padding: 20px; /* Add padding to content */
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: white;
}
  
  /* Add a background color to the submit button on mouse-over */
.contactus_form input[type=submit]:hover {
    background-color: #45a049;
}
@media only screen and (min-width:30rem){
    .contactus_form input[type=text],input[type=tel],input[type=email],textarea, select {
        width: 80%;
    }
    .contactus_form input[type=submit] {
        width: 70%;
    }   
}