
.documentContainer a{
    text-decoration: none;
    
}

.documentContainer h1 a{
    color: black;
}
.documentContainer ul{
    list-style: none;
    padding-inline-start: 0;
}
.documentContainer ul li{
    border: 1px solid black;
    background-color: white;
}
.documentContainer ul li a{
    display: block;
    padding:10px;
    color: darkblue;
}


@media only screen and (min-width:30rem){
    .documentContainer{
        display: grid;
        grid-template-columns: 30% 70%;
        width: 100%;
        /* height: 100%; */
        background-image: linear-gradient(to right,white 30%, #66c0b9);
        border-bottom: 1px solid gray;
        border-top-right-radius: 20px 20px;
    }

    .documentContainer ul li{
        border: 1px solid black;
        margin: 5px;
        text-align: left;
        width: 100%;
        background-color: white;
    }
    .documentContainer ul li{
        border: 1px solid black;
        margin: 5px;
        text-align: left;
        width: 100%;
        background-color: white;
    }
    .documentContainer ul li:hover{
        background-color: red;
    }
    .documentContainer ul li a{
        display: block;
        padding:5px;
        color: darkblue;
    }

}