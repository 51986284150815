.presMessage {
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 20px;
}

.presMessage h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.presMessage p {
    margin: 5px;
    font-size: 16px;
    line-height: 1.5;
}

@media only screen and (min-width:30rem) {
    
}