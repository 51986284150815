.articlePage{
    position: relative;
    z-index: 1;
    max-width: 1080px; /* Adjust max-width as needed */
    min-height: 80vh;
    margin: 0 auto; /* Center content horizontally */
    padding: 20px; /* Add padding to content */
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: white;
}
.articleContent{
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    min-height: 75vh;
}
.articleContent ol{
    text-align: left;
}
.articleContent ul{
    text-align: left;
}
.title{
   text-align: center; 
}
.date{
    text-align: center;
}
.content{
    padding: 20px;
    font-size: 18px;
}
.articleImage{
    max-width: 80vw;
}