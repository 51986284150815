.privacyPage{
    position: relative;
    z-index: 1;
    max-width: 1080px; /* Adjust max-width as needed */
    min-height: 80vh;
    margin: 0 auto; /* Center content horizontally */
    padding: 20px; /* Add padding to content */
    box-sizing: border-box; /* Include padding in width calculation */
    background-color: white;
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.privacyPage h1, h2 {
    color: #333;
}

.privacyPage ul {
    list-style-type: disc;
    margin-left: 20px;
    text-align: left;
}