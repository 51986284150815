.lang-select{
    font-size: 100%;
    font-family: "Helvetica",sans-serif;
    border: 0px;
    outline: 0px;
    background: #022a60;
    color:white;
    text-align-last: center;
    margin-top: 1px;
    margin-bottom: 0px;
    width: 150px;
}