.navbar{
    font-size: 100%;
    font-family: "Helvetica",sans-serif;
    transition: .3s;
    position: relative;
    z-index: 2;
}

.navigation{
    list-style: none;
    padding:0;
    background: #022a60;
}

.navigation li a{
    color:#ddd;
    text-decoration: none;
    padding: .8rem 1rem;
    display: block;
    line-height: 1;
    border-bottom: 1px dotted #ddd;
} 

.sticky{
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2000; 
}

/*burger icon */
.burger{
    visibility: visible;
    border-bottom: 1px dotted #ddd;
    padding-top:3px;
}

.burger:active{
    background-color: #146196;
}

/* mobile navbar dropdown */
.active-nav{
    display: none;
}

/* styling logo */
.logo{
    background: #022a60;
    line-height: 0;
    padding: 0.8rem 0rem;
    text-align: center;

}
.logo img{
    filter: contract(0);
    border-radius: 4px;
    width: 120px;
}

/* merging things together */
.logo, .navigation {
    margin: 0;
}

/* responsive nav bar*/
@media only screen and (min-width:30rem){
    .navbar{
        display: flex;
        justify-content: space-between;
        background:#022a60;
    }
    .navbar ul{
        display: flex;
        align-items: center;
        background: none;
        flex-wrap:wrap;
    }
    .navigation li{
        flex: 1 0 auto;
    }
    .navigation li a{
        border-bottom: none;
        padding: 1.2rem 1rem;
    }
    .navigation li a:hover{
        background: red;
    }

    .logo{
        padding: 0;
        margin-left: 1rem;
    }

    .logo img{
        height :40px;
        width: 40px;
        padding-top:0.5rem;
    }

    .burger{
        display: none;
    }

}